// Types
import { GetMediaPosts_getMediaPosts_entities } from 'api/mediaPost/types/GetMediaPosts';
// Constants
import { ATHLETES, SOCIAL_MEDIA_POST } from 'constants/routes';
// Helpers
import { getStreamerName } from 'helpers/streams';
// Ui
import ContentCard, { ContentType } from 'ui3/Card/ContentCard';

type MediaFeedListItemProps = {
  mediaFeed: GetMediaPosts_getMediaPosts_entities;
};

const MediaFeedListItem = ({ mediaFeed }: MediaFeedListItemProps) => {
  const mediaFeedSlug = mediaFeed.slug;
  const storeSlug = mediaFeed.store?.slug;

  const link = `${storeSlug}${SOCIAL_MEDIA_POST}${mediaFeedSlug}`;
  const storeName = getStreamerName(mediaFeed?.store);

  const isVideo = mediaFeed && 'video' in mediaFeed;

  const thumbnailImage = isVideo
    ? mediaFeed.thumbnailUrl || mediaFeed.video.thumbnailUrl || ''
    : mediaFeed.image.url || '';

  return (
    <ContentCard
      type={isVideo ? ContentType.VIDEO : ContentType.IMAGE}
      imageUrl={thumbnailImage}
      linkUrl={link}
      dateTime={mediaFeed?.scheduledAt || ''}
      tzCode={mediaFeed.scheduledAtTzCode || null}
      title={mediaFeed.title || ''}
      user={storeName || ''}
      userUrl={mediaFeed.store?.slug ? `/${mediaFeed.store?.slug}` : ATHLETES}
      isPlay={isVideo}
    />
  );
};

export default MediaFeedListItem;
