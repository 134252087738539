import React from 'react';
// Types
import { BaseCardProps } from './BaseCard/BaseCard';
import { AmaType, ProductTypesType } from 'api/graphql-global-types';
// Components
import BaseCard from './BaseCard/BaseCard';
import CardLabel from './CardLabel/CardLabel';
import { trackAddToCartAma } from 'helpers/metrics';

export type VideoTypes = ProductTypesType.Ama;

interface VideoCardProps extends BaseCardProps {
  type: VideoTypes | string;
  price?: string;
  videoURL?: string;
  videoId: string;
  amaAttributes?: {
    amaType: AmaType;
    amaSlug: string;
  };
  amaType?: AmaType;
  hideLabels?: boolean;
  showTitle?: boolean;
}

const TopLabels: React.FC<Partial<VideoCardProps>> = ({ type, size }) => {
  return <CardLabel text={type} size={size} variant="secondary" />;
};

const BottomLabels: React.FC<Partial<VideoCardProps>> = ({ price, size }) => {
  return (
    <>{price ? <CardLabel text={price} size={size} isCurrency /> : null}</>
  );
};

const VideoCard: React.FC<VideoCardProps> = ({
  type,
  size,
  title,
  price,
  imageUrl,
  videoURL,
  videoId,
  linkUrl,
  userUrl,
  width,
  height,
  amaAttributes,
  hideLabels = false,
  priorityImageLoading = false,
  showTitle = true,
  className,
  descriptionClassName,
  imageWrapperClassname,
}) => {
  const topLabels = TopLabels({ type });
  const bottomLabels = BottomLabels({ price });

  const onOrderAmaLinkClick = () => {
    if (amaAttributes) {
      trackAddToCartAma({
        id: videoId,
        price: Number(price),
        type: amaAttributes.amaType,
        slug: amaAttributes?.amaSlug ?? '',
        storeName: title ?? '',
      });
    }
  };

  return (
    <BaseCard
      className={className}
      descriptionClassName={descriptionClassName}
      imageWrapperClassname={imageWrapperClassname}
      imageUrl={imageUrl}
      titleUrl={userUrl}
      title={title}
      size={size}
      linkUrl={linkUrl}
      topLabels={hideLabels ? null : topLabels}
      bottomLabels={hideLabels ? null : bottomLabels}
      height={height}
      width={width}
      priorityImageLoading={priorityImageLoading}
      onLinkClick={amaAttributes ? onOrderAmaLinkClick : undefined}
      videoAttributes={{
        videoURL,
        videoId,
      }}
      showTitle={showTitle}
    />
  );
};

export default VideoCard;
