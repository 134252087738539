// Types
import { GetExperiences_getExperiences_entities } from 'api/experiences/types/GetExperiences';
import { GetExperiencesMinimalInfo_getExperiences_entities } from 'api/experiences/types/GetExperiencesMinimalInfo';
// Components
import ExperienceListItem from './components/ExperienceListItem/ExperienceListItem';
import CarouselSlider from '../CarouselSlider/CarouselSlider';

import styles from './ExperienceList.module.scss';

export type TypeMerchList = 'carousel' | 'store' | 'list' | 'ad';

type ExperienceListProps = {
  experiences:
    | GetExperiences_getExperiences_entities[]
    | GetExperiencesMinimalInfo_getExperiences_entities[];
  withCarousel?: boolean;
  itemType: TypeMerchList;
  showUser?: boolean;
  showInfiniteCarousel?: boolean;
};

type ExperienceItem = GetExperiences_getExperiences_entities;

const ExperienceList = ({
  experiences,
  withCarousel,
  itemType,
  showUser = true,
  showInfiniteCarousel = false,
}: ExperienceListProps) => {
  if (withCarousel) {
    return (
      <div className={styles.rootCarousel}>
        <CarouselSlider settings={{ infinite: showInfiniteCarousel }}>
          {experiences.map((experience: ExperienceItem) => (
            <ExperienceListItem
              key={experience.id}
              experience={experience}
              type="carousel"
              showUser={showUser}
            />
          ))}
        </CarouselSlider>
      </div>
    );
  }

  return (
    <ul className={styles.root}>
      {experiences.map((experience: ExperienceItem, index: number) => (
        <li className={styles.listItem} key={experience.id}>
          <ExperienceListItem
            experience={experience}
            priorityImageLoading={index <= 2}
            type={itemType}
            showUser={showUser}
          />
        </li>
      ))}
    </ul>
  );
};

export default ExperienceList;
