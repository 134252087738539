// Types
import {
  GetMediaPost_getMediaPost_VideoPost_store,
  GetMediaPost_getMediaPost_ImagePost_store,
} from 'api/mediaPost/types/GetMediaPost';
import {
  GetStoreBySlug_store,
  GetStoreBySlug_store_amas,
} from 'api/store/types/GetStoreBySlug';
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import { ProductTypesType } from 'api/graphql-global-types';
// Constants
import { ORDER_AMA } from 'constants/routes';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
// Ui
import Text from 'ui3/Text/Text';
import LinkButton from 'ui3/LinkButton/LinkButton';
import VideoCard from 'ui3/Card/VideoCard';
// Styles
import styles from './Ama.module.scss';
import MIcon from '../../../public/images/logos/millions-m.svg';

type AmaProps = {
  store:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
  ama: GetStoreBySlug_store_amas | undefined;
};

const Ama = ({ ama, store }: AmaProps) => {
  if (!ama) {
    return null;
  }

  const amaPrice = Number(ama?.price || 0);
  const storeSlug = store?.slug || '';
  const storeName = store?.storeDetails?.storeName || '';
  const amaVideoURL = ama?.videoURL || '';
  const videoThumbnailURL = ama?.videoThumbnailURL;

  return (
    <section className={styles.root}>
      <div className={styles.mainBlock}>
        <VideoCard
          className={styles.videoCard}
          imageWrapperClassname={styles.amaVideo}
          type={ProductTypesType.Ama}
          imageUrl={videoThumbnailURL ?? ''}
          price={formatCurrencyString(amaPrice)}
          videoURL={amaVideoURL}
          videoId={ama.id}
          amaAttributes={{
            amaType: ama.type,
            amaSlug: storeSlug,
          }}
          hideLabels
        />

        <div className={styles.body}>
          <Text variant="h4" className={styles.title}>
            {storeName} Personal Video
          </Text>
          <Text variant="body1Regular16" className={styles.description}>
            I&apos;m here to answer your questions about training, about my
            journey, my predictions on the next big event, or anything you want
            to know. Hit me up and ask me anything!
          </Text>
          <div className={styles.bookContainer}>
            <Text variant="h5" className={styles.mobilePrice}>
              {formatCurrencyString(amaPrice)}
            </Text>
            <LinkButton
              link={`${ORDER_AMA}/${storeSlug}`}
              className={styles.linkButton}
            >
              Book A Personalized Video
            </LinkButton>
            <Text variant="h5" className={styles.desktopPrice}>
              {formatCurrencyString(amaPrice)}
            </Text>
          </div>
        </div>
        <div className={styles.logoContainer}>
          <MIcon />
        </div>
      </div>
    </section>
  );
};

export default Ama;
