import { gql } from '@apollo/client';

export const MediaImageFields = gql`
  fragment MediaImageFields on MediaPostImage {
    id
    url
  }
`;

export const MediaVideoFields = gql`
  fragment MediaVideoFields on MediaPostVideo {
    duration
    id
    jobProgress
    jobStatus
    thumbnailUrl
    url
    key
  }
`;

export const MediaPostFields = gql`
  fragment MediaPostFields on MediaPost {
    id
    slug
    scheduledAt
    scheduledAtOffset
    scheduledAtTzCode
    buyProductLink
    mentions {
      id
      store {
        slug
        id
        storeDetails {
          storeName
        }
      }
      unregisteredContact {
        id
        email
        representativeName
        phoneNumber
        name
        url
        type
        socialMediaLink
      }
    }
  }
`;
