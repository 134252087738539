import { useQuery } from '@apollo/client';
// Api
import { GET_MEDIA_POSTS } from 'api/mediaPost/queries';
// Types
import {
  GetMediaPost_getMediaPost,
  GetMediaPost_getMediaPost_ImagePost_store,
  GetMediaPost_getMediaPost_VideoPost_store,
} from 'api/mediaPost/types/GetMediaPost';
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import {
  GetMediaPosts,
  GetMediaPostsVariables,
} from 'api/mediaPost/types/GetMediaPosts';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import { StoreStatus } from 'api/graphql-global-types';
// Constants
import { MEDIA_POSTS_LIBRARY } from 'constants/routes';
// Components
import Section from 'components/common3/Section/Section';
import MediaFeedList from './MediaFeedList/MediaFeedList';

type RelatedMediaFeedProps = {
  store:
    | GetStoreBySlug_store
    | GetMediaPost_getMediaPost_ImagePost_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetStream_stream_store;
  currentMediaFeed?: GetMediaPost_getMediaPost;
  title?: string;
  showPendingStoreItems?: boolean;
  className?: string;
  link?: string;
};

const RelatedMediaFeed = ({
  store,
  currentMediaFeed,
  title,
  showPendingStoreItems = false,
  className,
  link,
}: RelatedMediaFeedProps) => {
  const { data } = useQuery<GetMediaPosts, GetMediaPostsVariables>(
    GET_MEDIA_POSTS,
    {
      variables: {
        input: {
          storeId: store.id,
          storeStatuses: [
            StoreStatus.Active,
            ...(showPendingStoreItems ? [StoreStatus.Pending] : []),
          ],
          limit: 8,
        },
      },
      fetchPolicy: 'network-only',
    }
  );

  const mediaFeeds = currentMediaFeed
    ? data?.getMediaPosts?.entities?.filter(
        ({ id }) => id !== currentMediaFeed.id
      ) || []
    : data?.getMediaPosts?.entities || [];

  const pageUrl = link ?? `${MEDIA_POSTS_LIBRARY}/${store.slug}`;

  if (!mediaFeeds.length) {
    return null;
  }

  return (
    <Section
      title={title ? title : 'Media Feed'}
      seeAllLink={pageUrl}
      seeAllLinkTitle="See Media"
      className={className}
    >
      <MediaFeedList
        mediaFeeds={mediaFeeds}
        withCarousel
        showInfiniteCarousel={mediaFeeds.length > 4}
      />
    </Section>
  );
};

export default RelatedMediaFeed;
