import { useRef } from 'react';
import cn from 'classnames';
// Types
import Slider, { Settings } from 'react-slick';
// Ui
import Icon from 'ui/Icon/Icon';
import Carousel from 'ui/Carousel/Carousel';
// Styles
import styles from './CarouselWithArrows.module.scss';

const sliderSettings = {
  infinite: true,
  adaptiveHeight: false,
  speed: 500,
  swipeToSlide: true,
  variableWidth: true,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
} as Settings;

type CarouselWithArrowsProps = {
  settings?: Settings;
  theme?: Theme;
  hideArrows?: boolean;
};

const CarouselWithArrows: React.FC<CarouselWithArrowsProps> = ({
  children,
  settings,
  hideArrows = false,
  theme = 'black',
}) => {
  const sliderRef = useRef<Slider | null>(null);

  const handleSlickNextClick = () => {
    sliderRef.current?.slickNext();
  };

  const handleSlickPrevClick = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <div
      className={cn(styles.root, {
        [styles[`theme-${theme}`]]: theme,
      })}
    >
      <div
        className={cn(styles.nav, {
          [styles.navHide]: hideArrows,
        })}
      >
        <Icon
          name="chevron-left-new"
          role="button"
          className={styles.arrowIcon}
          onClick={handleSlickPrevClick}
        />

        <Icon
          name="chevron-right-new"
          role="button"
          className={styles.arrowIcon}
          onClick={handleSlickNextClick}
        />
      </div>

      <Carousel
        ref={sliderRef}
        {...sliderSettings}
        {...settings}
        className={styles.slider}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselWithArrows;
