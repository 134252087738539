import cn from 'classnames';
// Types
import { GetExperiences_getExperiences_entities } from 'api/experiences/types/GetExperiences';
import { GetExperiencesMinimalInfo_getExperiences_entities } from 'api/experiences/types/GetExperiencesMinimalInfo';
import { ProductTypesType } from 'api/graphql-global-types';
// Constants
import { ATHLETES } from 'constants/routes';
// Helpers
import { formatCurrencyString } from 'helpers/formatCurrencyString';
// Ui
import ProductCard from 'ui3/Card/ProductCard';
// Styles
import styles from './ExperienceListItem.module.scss';

type ExperienceListItemProps = {
  experience:
    | GetExperiences_getExperiences_entities
    | GetExperiencesMinimalInfo_getExperiences_entities;
  type?: 'list' | 'carousel' | 'store' | 'default' | 'ad' | 'related';
  priorityImageLoading?: boolean;
  showUser?: boolean;
};

const ExperienceListItem = ({
  experience,
  type = 'list',
  priorityImageLoading,
  showUser = true,
}: ExperienceListItemProps) => {
  const isDefault = type === 'default';
  const isAd = type === 'ad';
  const isRelated = type === 'related';

  const price = formatCurrencyString(experience.price);
  const link = `/${experience?.store?.slug}/experience/${experience.slug}`;
  const storeName = experience?.store?.storeDetails?.storeName || '';

  const mainImage = experience?.images?.find((item) => item.isMainImage);

  return (
    <ProductCard
      className={cn(styles.root, {
        [styles.default]: isDefault,
        [styles.ad]: isAd,
        [styles.related]: isRelated,
      })}
      descriptionClassName={styles.infoWrapper}
      type={ProductTypesType.Experience}
      hideCategory
      title={experience.title}
      price={price}
      linkUrl={link}
      imageUrl={mainImage?.experienceImageFileKey ?? ''}
      {...(showUser && {
        user: storeName,
        userUrl: experience.store?.slug
          ? `/${experience.store?.slug}`
          : ATHLETES,
      })}
      priorityImageLoading={priorityImageLoading}
    />
  );
};

export default ExperienceListItem;
