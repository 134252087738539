// Types
import { GetMediaPosts_getMediaPosts_entities } from 'api/mediaPost/types/GetMediaPosts';
// Components
import MediaFeedListItem from './MediaFeedListItem/MediaFeedListItem';
// Styles
import styles from './MediaFeedList.module.scss';
import CarouselSlider from 'components/common3/CarouselSlider/CarouselSlider';

type MediaFeedListProps = {
  mediaFeeds: GetMediaPosts_getMediaPosts_entities[];
  withCarousel?: boolean;
  showInfiniteCarousel?: boolean;
};

const MediaFeedList = ({
  mediaFeeds,
  withCarousel,
  showInfiniteCarousel,
}: MediaFeedListProps) => {
  if (withCarousel) {
    return (
      <div className={styles.rootCarousel}>
        <CarouselSlider settings={{ infinite: showInfiniteCarousel }}>
          {mediaFeeds.map((item: GetMediaPosts_getMediaPosts_entities) => (
            <MediaFeedListItem key={item.id} mediaFeed={item} />
          ))}
        </CarouselSlider>
      </div>
    );
  }
  return (
    <ul className={styles.root}>
      {mediaFeeds.map((item: GetMediaPosts_getMediaPosts_entities) => (
        <li className={styles.listItem} key={item.id}>
          <MediaFeedListItem mediaFeed={item} />
        </li>
      ))}
    </ul>
  );
};

export default MediaFeedList;
