import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query GetArticleForStore($input: ArticleStoreFilterInput!) {
    getArticleForStore(input: $input) {
      entities {
        id
        content
        createdAt
        hashtags {
          id
          name
        }
        imageLabel
        key
        slug
        status
        store {
          slug
          storeDetails {
            storeName
          }
        }
        title
        url
      }
      limit
      offset
      total
    }
  }
`;

export const GET_ARTICLE_BY_SLUG = gql`
  query GetArticleBySlug($input: ArticleBySlugInput!) {
    getArticleBySlug(input: $input) {
      articleUrl
      id
      content
      createdAt
      hashtags {
        id
        name
      }
      mentions {
        id
        mentionType
        targetType
        store {
          slug
          id
          storeDetails {
            storeName
            storeId
            avatarURL
          }
        }
        unregisteredContact {
          id
          email
          representativeName
          phoneNumber
          name
          url
          type
          socialMediaLink
        }
      }
      imageLabel
      key
      slug
      status
      store {
        slug
        storeDetails {
          storeName
        }
      }
      title
      url
    }
  }
`;
