// Types
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import { MemorabiliaProductType, UserRole } from 'api/graphql-global-types';
import { GetMemorabiliaMinimalInfo_getMemorabilia_entities } from 'api/memorabilia/types/GetMemorabiliaMinimalInfo';
// Constants
import { PRODUCTS } from 'constants/routes';
// Ui
import Text from 'ui3/Text/Text';
import LinkButton from 'ui3/LinkButton/LinkButton';
import ProductCard from 'ui3/Card/ProductCard';
// Helpers
import { formatPrice } from 'helpers/formatPrice';
// Styles
import styles from './Memorabilia.module.scss';

type MemorabiliaProps = {
  store: GetStoreBySlug_store;
  memorabilia?: GetMemorabiliaMinimalInfo_getMemorabilia_entities | null;
  isPage?: boolean;
};

const Memorabilia = ({ store, memorabilia }: MemorabiliaProps) => {
  const memorabiliaTitle = memorabilia?.title || '';
  const description = memorabilia?.description || '';
  const imageFileUrl =
    memorabilia?.images?.find(({ isMainImage }) => isMainImage)?.url || '';
  const price = memorabilia?.price || 0;
  const memorabiliaUrl = memorabilia?.slug || '';
  const storeSlug = memorabilia?.store.slug || '';

  const isAthlete = store.role === UserRole.Athlete;
  const isOrganization = store.role === UserRole.Organization;
  const isContentCreator = store.role === UserRole.ContentCreator;

  const pageUrl = isAthlete
    ? `${PRODUCTS}?athleteIds=${store.id}`
    : isOrganization
    ? `${PRODUCTS}?organizationIds=${store.id}`
    : isContentCreator
    ? `${PRODUCTS}?contentCreatorIds=${store.id}`
    : '';

  const memorabiliaProductType = memorabilia?.memorabiliaProductType || '';
  const isExternalLink = pageUrl?.startsWith('http');

  return (
    <section className={styles.root}>
      <div className={styles.header}>
        <Text variant="h5">Epic Memorabilia</Text>
        <LinkButton
          link={pageUrl}
          variant="secondary"
          subVariant="text"
          icon="chevronRight"
          iconPosition="right"
          iconSize={16}
          className={styles.seeAllLinkButton}
          target={isExternalLink ? '_blank' : undefined}
          rel={isExternalLink ? 'noopener' : undefined}
        >
          See All{' '}
          {memorabiliaProductType === MemorabiliaProductType.Memorabilia
            ? 'Memorabilia'
            : 'Products'}
        </LinkButton>
      </div>
      <div className={styles.mainBlock}>
        <ProductCard
          className={styles.productCard}
          type={
            memorabilia?.memorabiliaProductType ??
            MemorabiliaProductType.Memorabilia
          }
          imageUrl={imageFileUrl}
          size="large"
        />

        <div className={styles.body}>
          <Text variant="h3" className={styles.title}>
            {memorabiliaTitle}
          </Text>
          <Text
            variant="body1Regular16"
            color="lights-medium"
            className={styles.description}
          >
            {description}
          </Text>
          <div className={styles.bookContainer}>
            <LinkButton
              target="_blank"
              rel="noreferrer"
              link={`/${storeSlug}/memorabilia/${memorabiliaUrl}`}
              className={styles.linkButton}
            >
              View - {formatPrice(price)}
            </LinkButton>
            <LinkButton
              link={pageUrl}
              variant="secondary"
              subVariant="text"
              icon="chevronRight"
              iconPosition="right"
              iconSize={16}
              className={styles.seeAllLinkButton}
              target={isExternalLink ? '_blank' : undefined}
              rel={isExternalLink ? 'noopener' : undefined}
            >
              See All{' '}
              {memorabiliaProductType === MemorabiliaProductType.Memorabilia
                ? 'Memorabilia'
                : 'Products'}
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Memorabilia;
