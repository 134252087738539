import { useRouter } from 'next/router';
// Types
import { GetStream_stream_store } from 'api/streams/types/GetStream';
import {
  MemorabiliaOrderBy,
  MemorabiliaProductType,
  MemorabiliaStatus,
} from 'api/graphql-global-types';
import { GetStoreBySlug_store } from 'api/store/types/GetStoreBySlug';
import {
  GetMediaPost_getMediaPost_ImagePost_store,
  GetMediaPost_getMediaPost_VideoPost_store,
} from 'api/mediaPost/types/GetMediaPost';
// Helpers
import { getCheapestActiveAma } from 'helpers/ama';
import { parseToPlainText } from 'helpers/textEditor';
import { getEnvLink } from 'helpers/routes';
// Hooks
import { useGetAthleteMemorabilia } from 'hooks';
// Layouts
import Head from 'layouts/Head/Head';
import Header from 'layouts/Header/Header';
import Main from 'layouts/Main/Main';
import Footer from 'layouts/Footer/Footer';
// Components
import Section from 'components/common3/Section/Section';
import Ama from '../Ama/Ama';
import Streams from '../Streams/Streams';
import Description from '../Description/Description';
import Memorabilia from '../Memorabilia/Memorabilia';
import RelatedExperience from 'components/Experience/components/RelatedExperience/RelatedExperience';
import RelatedMediaFeed from 'components/MediaFeed/RelatedMediaFeed/RelatedMediaFeed';
import RelatedProfileProductsList from '../components/RelatedProfileProductsList/RelatedProfileProductsList';
import Articles from '../Articles/Articles';
// Styles
import styles from './AthleteContainer.module.scss';

type AthleteContainerProps = {
  store?:
    | GetStoreBySlug_store
    | GetStream_stream_store
    | GetMediaPost_getMediaPost_VideoPost_store
    | GetMediaPost_getMediaPost_ImagePost_store;
};

const AthleteContainer = ({ store }: AthleteContainerProps) => {
  const router = useRouter();
  const athleteSlug = router.query.athleteSlug;

  const { data } = useGetAthleteMemorabilia({
    storeId: store?.id || '',
    variables: {
      productTypes: [MemorabiliaProductType.Memorabilia],
      statuses: [MemorabiliaStatus.Active],
      limit: 1,
      orderBy: MemorabiliaOrderBy.price,
    },
    skip: !store || !store.hasMemorabilia,
  });

  const memorabilia = data?.getMemorabilia?.entities?.[0];

  if (!store) {
    return null;
  }

  const name = store?.storeDetails?.storeName || '';
  const description =
    store?.storeDetails?.description || store?.storeDetails?.bio || '';
  const metaTitle = `${name}: Streams, Merchandise, Personal Videos | MILLIONS`;
  const metaDescription = `${parseToPlainText(
    description
  )} ${name}: Watch live streams, buy ${name} merch, ask questions, and get answered on MILLIONS.`;
  const avatarUrl = store?.storeDetails?.avatarURL || '';
  const cheapestActiveAma = getCheapestActiveAma(store?.amas || []);

  const shouldShowMemorabiliaSection = store.hasMemorabilia;

  const hasMerchProducts =
    store.hasMerch || store.hasMemorabilia || store.hasPureProducts;

  const seeMerchLink = `${athleteSlug}/merch`; // go to this profile's merch page instead of all merch page
  const seeProductLink = `${athleteSlug}/products`; // same, only products
  const seeExperienceLink = `${athleteSlug}/experiences`; // same, only experience
  const seeStreamsLink = `${athleteSlug}/streams`; // same, only streams
  const seeMediaLink = `${athleteSlug}/media`; // same, only media feed

  const isNotEmpty =
    shouldShowMemorabiliaSection ||
    hasMerchProducts ||
    store.hasAma ||
    store.hasExperiences ||
    store.hasStreams ||
    store.hasMediaPost ||
    store.hasArticle;

  const canonicalLink = `${getEnvLink()}/${athleteSlug}`;

  return (
    <>
      <Head
        title={metaTitle}
        description={metaDescription}
        image={avatarUrl}
        canonicalLink={canonicalLink}
      />
      <Header />

      <Main withoutSidesPadding>
        <Description store={store} />
        {isNotEmpty && (
          <div className={styles.root}>
            {!store.hasActiveStreams && shouldShowMemorabiliaSection && (
              <Memorabilia
                store={store}
                memorabilia={memorabilia}
                isPage={false}
              />
            )}

            <Streams
              storeId={store.id}
              storeSlug={store.slug}
              storeRole={store.role}
              className={styles.adjustedPosition}
              link={seeStreamsLink}
              hasActiveStream={store.hasActiveStreams}
            />

            {store.hasMerch && (
              <Section
                title="Latest Merch"
                seeAllLink={seeMerchLink}
                seeAllLinkTitle="See Shop"
                className={styles.adjustedPosition}
              >
                <RelatedProfileProductsList
                  className={styles.relatedProducts}
                  storeId={store?.id || ''}
                  shouldHideMostExpensiveItem
                  showOnlyMerch
                />
              </Section>
            )}

            {store.hasPureProducts && (
              <Section
                title="Products"
                seeAllLink={seeProductLink}
                seeAllLinkTitle="Shop All Products"
                className={styles.adjustedPosition}
              >
                <RelatedProfileProductsList
                  className={styles.relatedProducts}
                  storeId={store?.id || ''}
                  shouldHideMostExpensiveItem
                  isProductDetailsPage={false}
                  showOnlyProducts
                />
              </Section>
            )}

            <RelatedExperience
              store={store}
              className={styles.adjustedPosition}
              link={seeExperienceLink}
            />

            <Ama ama={cheapestActiveAma} store={store} />

            <RelatedMediaFeed
              store={store}
              className={styles.adjustedPosition}
              link={seeMediaLink}
            />

            {store.hasArticle && (
              <Articles
                storeId={store.id}
                className={styles.adjustedPosition}
              />
            )}
          </div>
        )}
      </Main>

      <Footer />
    </>
  );
};

export default AthleteContainer;
