import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
// Api
import { GET_ARTICLES } from 'api/articles/queries';
// Types
import type {
  GetArticleForStore,
  GetArticleForStore_getArticleForStore_entities,
  GetArticleForStoreVariables,
} from 'api/articles/types/GetArticleForStore';
import { ArticleStoreFilterInput } from 'api/graphql-global-types';
// Ui
import FourDotsLoader from 'ui/FourDotsLoader/FourDotsLoader';
// Components
import Section from 'components/common3/Section/Section';
import NotFound from 'components/common/NotFound/NotFound';
import ContentCard, { ContentType } from 'ui3/Card/ContentCard';
import CarouselSlider from 'components/common3/CarouselSlider/CarouselSlider';

// Constants
import { ATHLETES } from 'constants/routes';
// Styles
import styles from './Articles.module.scss';

const PER_PAGE = 9;

type ArticlesProps = {
  storeId: string;
  className?: string;
  link?: string;
};

export const Articles = ({
  storeId,
  className,
  link,
}: ArticlesProps): JSX.Element | null => {
  const inputVariables = useMemo(
    (): ArticleStoreFilterInput => ({
      storeId,
      // statuses: [ArticleStatus.Active],
      limit: PER_PAGE,
      offset: 0,
    }),
    [storeId]
  );

  const { data, loading } = useQuery<
    GetArticleForStore,
    GetArticleForStoreVariables
  >(GET_ARTICLES, {
    variables: {
      input: inputVariables,
    },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  });

  const sponsoredArticles = data?.getArticleForStore?.entities || [];
  const total = data?.getArticleForStore?.total;
  const isNotFound = !loading && !total;

  if (!storeId) {
    return null;
  }

  const seeAllLink = link ?? `${sponsoredArticles[0]?.store?.slug}/news`;

  if (loading) {
    return <FourDotsLoader />;
  }

  return (
    <Section
      title="Latest Articles"
      seeAllLink={seeAllLink}
      seeAllLinkTitle="Read Articles"
      className={className}
    >
      <div className={styles.rootCarousel}>
        <CarouselSlider settings={{ infinite: sponsoredArticles.length > 5 }}>
          {sponsoredArticles.map(
            (article: GetArticleForStore_getArticleForStore_entities) => (
              <ContentCard
                key={article.id}
                type={ContentType.NEWS}
                imageUrl={article.url}
                tzCode={null}
                linkUrl={`${article.store.slug}/news/${article.slug}`}
                dateTime={article.createdAt || ''}
                title={article.title || ''}
                user={article.store?.storeDetails?.storeName || ''}
                userUrl={
                  article.store?.slug ? `/${article.store?.slug}` : ATHLETES
                }
                isPlay={false}
                isArticleCard
                hideCategory
              />
            )
          )}
        </CarouselSlider>
      </div>
      {isNotFound && <NotFound title="No Articles" />}
    </Section>
  );
};

export default Articles;
